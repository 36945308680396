body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*: #7298ff;*/
span.role{
  display: flex;
  margin: 1px;
  background-color: hsl(195, 60%, 85%);
  padding: 4px;
  border-radius: 15px;
}


div.buttonbox{
  display: flex;
  justify-content: space-around;
  margin-bottom: 1em;
  margin-top: 1em;
}

div.buttonstack{
  margin-right: .5rem;
  margin-left: .5rem;
  margin-bottom: .5em;
}

div.main-body{
  margin-right: 1em;
  margin-left: 1em;
}

ul.nobullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button.datebutton {
  width: 100%;
  white-space:nowrap;

}



  .footer {
    width: 100%;
    padding-top: 1em;
    padding-bottom: 1em;

    
  }

  @media only screen and (max-width: 600px) {

    .footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: white;
    }
  }

div.button-rows{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
 
}



thead.table-header{
  background-color: hsl(206, 75%, 70%);/* rgb(130, 210, 235);/*#5395cf; #3c85c5;*/
  border: 2px solid black;
}

thead.table-header > tr{
  color: hsl(0, 100%, 100%);
  font-weight: bold;

}


td.muikee {
  background: linear-gradient(to right, #ff0066 0%, #ffccff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}